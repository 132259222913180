import { CustomVenueService } from '../services'

export const state = {
	venuesAu: [],
	venuesUk: []
}

export const actions = {
	getAll ({ commit, dispatch }, country) {
		return CustomVenueService.getAll(country)
			.then(venues => {
				venues = venues.venues
				commit('setCustomVenues', { venues, country })
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	createCustomVenue ({ state, commit, dispatch }, params) {
		return CustomVenueService.updateVenueNotes(params)
			.then(results => {
				commit('updateNotes', results.data)
				return results.data
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	}
}
export const mutations = {
	setCustomVenues (state, data) {
		if (data.country == 'AU') {
			state.venuesAu = data.venues;
		} else {
			state.venuesUk = data.venues;
		}
	},
}
export const getters = {
	getVenues: (state) => (country) => {
		let venues = [];
		if (country == 'AU') {
			venues = state.venuesAu;
		} else {
			venues = state.venuesUk;
		}

		return venues;
	},
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
