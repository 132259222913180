import { InspectionService } from '@/services';

const state = {
  items: [],
  bookings : [],
  customInspections: [],
  itemsByUniqueId: [],
};

const actions = {
  getInspections({ commit }, leadEventId) {
    return InspectionService.getInspectionByLeadEventId(leadEventId)
      .then((inspections) => {
        inspections = inspections.data;
        commit('UpdateLeadInspections', { leadEventId, inspections });
      })
      .catch((error) => {
        dispatch('alert/error', error, { root: true });
      });
  },
  getSalesWithoutInspections({ commit }, leadEventId) {
    return InspectionService.getSalesWithoutInspections(leadEventId)
      .then((bookings) => {
        bookings = bookings.data;
        commit('updateBookings', { leadEventId, bookings });
      })
      .catch((error) => {
        dispatch('alert/error', error, { root: true });
      });
  },
  getCustomVenueInspections({ commit }, leadEventId) {
    return InspectionService.getCustomVenueInspections(leadEventId)
      .then((customInspections) => {
        customInspections = customInspections.data;
        commit('updateCustomInspections', { leadEventId, customInspections });
      })
      .catch((error) => {
        dispatch('alert/error', error, { root: true });
      });
  },
  getInspectionByKey({ commit }, uniqueId) {
    return InspectionService.getInspectionByKey(uniqueId)
      .then((inspection) => {
        inspection = inspection.data;
        commit('UpdateInspectionsForUniqueKeys', { uniqueId, inspection });
      });
  },
  addInspectionRequest({ commit, dispatch }, data) {
    return InspectionService.addInspectionRequest(data.leadEventId, data.formData)
      .then((shortlist) => {
      }).catch((error) => {
        dispatch('alert/error', error, { root: true });
      });
  },
  editInspectionRequest({commit, dispatch}, data) {
    return InspectionService.editInspectionRequest(data.leadEventId, data.formData)
      .then((shortlist) => {
      }).catch((error) => {
        dispatch('alert/error', error, { root: true });
      });
  },
  confirmInspectionBooking({ commit }, data) {
    return InspectionService.confirmInspectionBooking(data.inspection_unique_id, data.formData);
  },
  cancelInspection({ commit }, inspection_id) {
    return InspectionService.cancelInspection(inspection_id).catch((error) => {
      dispatch('alert/error', error, { root: true });
    });;
  },
};

const mutations = {
  UpdateLeadInspections(state, data) {
    state.items = state.items.filter(function(item) {
      return parseInt(item.leadEventId) !== parseInt(data.leadEventId);
    });
    state.items.push(data);
  },
  updateBookings(state, data) {
    state.bookings = state.bookings.filter(function(item) {
      return parseInt(item.leadEventId) !== parseInt(data.leadEventId);
    });
    state.bookings.push(data);
  },
  updateCustomInspections(state, data) {
    state.customInspections = state.customInspections.filter(function(item) {
      return parseInt(item.leadEventId) !== parseInt(data.leadEventId);
    });
    state.customInspections.push(data);
  },
  UpdateInspectionsForUniqueKeys(state, data) {
    state.itemsByUniqueId = state.itemsByUniqueId.filter(function(item) {
      return item.uniqueId !== data.uniqueId;
    });
    state.itemsByUniqueId.push(data);
  },
};

const getters = {
  getInspectionsByLeadEventId: (state) => (leadEventId) => {
    let item = state.items.find((item) => parseInt(item.leadEventId) === parseInt(leadEventId));
    return (item && item.inspections) || {};
  },
  getBookingsByLeadEventId: (state) => (leadEventId) => {
    let item = state.bookings.find((item) => parseInt(item.leadEventId) === parseInt(leadEventId));
    return (item && item.bookings) || {};
  },
  getCustomVenueInspectionsByLeadEventId: (state) => (leadEventId) => {
    let item = state.customInspections.find((item) => parseInt(item.leadEventId) === parseInt(leadEventId));
    return (item && item.customInspections) || {};
  },
  getInspectionsById: (state) => (leadEventId, inspectionId) => {
    let item = state.items.find((item) => parseInt(item.leadEventId) === parseInt(leadEventId));
    let inspections = (item && item.inspections) || {};
    let inspectionItem = inspections.find((item) => parseInt(item.id) === parseInt(inspectionId));
    return inspectionItem || {};
  },
  getInspectionsByUniqueId: (state) => (uniqueId) => {
    let item = state.itemsByUniqueId.find((item) => item.uniqueId === uniqueId);
    return (item && item.inspection) || {};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
