import { HTTP } from '../services'
import buildQuery from '@/helpers/url'

export default new (class CustomVenueService {
	getAll (country) {
		return HTTP.get('/v1/venues/custom/all?country=' + country)
	}

	// Update a specific special offer for a venue
	createCustomVenue(data) {
		return HTTP.post(`/v1/venues/custom`, data);
	}

	createCustomBooking(customVenueId, leadEventId, data) {
		return HTTP.post(
			'/v1/sales/lead-event/' +
        	leadEventId +
        	'/custom-venue-sale/' + 
			customVenueId,
			data
		)
	}

	createCustomSiteVisit(leadEventId, data) {
		return HTTP.post(
			'/v1/lead-event/' +
        	leadEventId +
        	'/custom-inspection',
			data
		)
	}
})()
