import { VenueService } from '../services'
import Vue from 'vue'

export const state = {
	items: [],
	singleItems: [],
	venueLeads: [],
	venueLeadsTotal: [],
	venueEmailLogTotal: [],
	venueEmailLog: [],
	venueRecommendations: [],
	venueRecommendationsTotal: [],
	venueStyles: [],
	venueRegions: [],
	venueRegionsFiltered: [],
	venueCities: [],
	venueSpecialists: [],
	descriptions: {},
	packages: {},
	notes: {},
	topVenuesLossTo: {},
	lostReasons: {},
	venueLostStatus: {},
	venueLinkedEmails: {},
	venueSpecialOffers: {}
}

export const actions = {
	getAll ({ commit, dispatch }, params) {
		return VenueService.getAll(params)
			.then(venues => {
				venues = venues.data
				commit('setVenues', venues)
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	getSingle ({ commit, dispatch }, data) {
		return VenueService.getVenueById(data)
			.then(venue => {
				venue = venue.data
				commit('updateSingleVenue', venue)
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	getVenueLeads ({ commit, dispatch }, data) {
		return VenueService.getVenueLeads(data.venueId, data.page)
			.then(results => {
				commit('updateVenueLeads', {
					leads: results.data,
					total: results.meta.total,
					venueId: data.venueId
				})
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	getVenueEmailLogs ({ commit, dispatch }, data) {
		return VenueService.getVenueEmailLogs(data.venueId, data.page)
			.then(results => {
				commit('updateVenueEmailLog', {
					emailLogs: results.data,
					total: results.meta.total,
					venueId: data.venueId
				})
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	getVenueRecommendations ({ commit, dispatch }, data) {
		return VenueService.getVenueRecommendations(data.venueId, data.page)
			.then(results => {
				commit('updateVenueRecommendations', {
					recommendations: results.data,
					total: results.meta.total,
					venueId: data.venueId
				})
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	getVenueLostStatus ({ commit, dispatch }) {
		return VenueService.getVenueLostStatus()
			.then(results => {
				commit('updateVenueStatus', results.data)
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},

	getStyles ({ commit, dispatch }) {
		return VenueService.getVenueStyles()
			.then(results => {
				commit('updateVenueStyles', results.data)
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	getRegions ({ commit, dispatch }, country) {
		return VenueService.getVenueRegions(country)
			.then(results => {
				commit('updateVenueRegions', {data: results.data, country})
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	getCities ({ commit, dispatch }, country) {
		return VenueService.getVenueCities(country)
			.then(results => {
				commit('updateVenueCities', {data: results.data, country})
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	getVenueSpecialists ({ commit, dispatch }) {
		return VenueService.getVenueSpecialists()
			.then(results => {
				commit('updateVenueSpecialists', results.data)
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	search ({ commit, dispatch }, params) {
		return VenueService.searchVenues(params)
			.then(results => {
				return results.data
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	async karenSearch ({ commit, dispatch }, params) {
		return await VenueService.karenVenueSearch(params);
	},
	getVenueDescription ({ state, commit, dispatch }, params) {
		if (state.descriptions[params.venue_id]) {
			return Promise.resolve(state.descriptions[params.venue_id])
		}
		return VenueService.venueDescription(params)
			.then(results => {
				commit('updateDescription', results.data)
				return results.data
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	getVenuePackages ({ state, commit, dispatch }, params) {
		if (state.packages[params.venue_id]) {
			return Promise.resolve(state.packages[params.venue_id])
		}
		return VenueService.venuePackages(params)
			.then(results => {
				commit('updatePackages', results.data)
				return results.data
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	getVenueNotes ({ state, commit, dispatch }, params) {
		if (state.notes[params.venue_id]) {
			return Promise.resolve(state.notes[params.venue_id])
		}
		return VenueService.getVenueNotes(params)
			.then(results => {
				commit('updateNotes', results.data)
				return results.data
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	getTopVenuesLossTo ({ state, commit, dispatch }, params) {
		if (state.topVenuesLossTo[params.venue_id]) {
			return Promise.resolve(state.topVenuesLossTo[params.venue_id])
		}
		return VenueService.getTopVenuesLossTo(params)
			.then(results => {
				commit('updateTopVenuesLossTo', {
					venues: results.data,
					venueId: params.venue_id
				})
				return results.data
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	getLostReasons ({ state, commit, dispatch }, params) {
		if (state.lostReasons[params.venue_id]) {
			return Promise.resolve(state.lostReasons[params.venue_id])
		}
		return VenueService.getLostReasons(params)
			.then(results => {
				commit('updateLostReasons', {
					reasons: results.data,
					venueId: params.venue_id
				})
				return results.data
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	getVenueLinkedEmails ({ state, commit, dispatch }, params) {
		return VenueService.getVenueLinkedEmails(params)
			.then(results => {
				commit('updateVenueLinkedEmails', {
					emails: results.data,
					venueId: params.venue_id
				})
				return results.data
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	getVenueSpecialOffers ({ state, commit, dispatch }, venueId) {
		return VenueService.getSpecialOffers(venueId)
			.then(results => {
				commit('updateVenueSpecialOffers', {
					offers: results.data,
					venueId: venueId
				})
				return results.data
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	},
	updateVenueNotes ({ state, commit, dispatch }, params) {
		return VenueService.updateVenueNotes(params)
			.then(results => {
				commit('updateNotes', results.data)
				return results.data
			})
			.catch(error => {
				dispatch('alert/error', error, { root: true })
			})
	}
}
export const mutations = {
	setVenues (state, venues) {
		state.items = venues
	},
	updateSingleVenue (state, venue) {
		state.singleItems = state.singleItems.filter(function (item) {
			return parseInt(item.id) !== parseInt(venue.id)
		})
		state.singleItems.push(venue)
	},
	updateVenueLeads (state, data) {
		let current = VenueService.getVenueLeadsByVenueId(data.venueId)
		let formatedLeads = VenueService.formatVenueComponentData(
			data.leads,
			current
		)
		Vue.set(state.venueLeadsTotal, data.venueId, data.total)
		Vue.set(state.venueLeads, data.venueId, formatedLeads)
	},
	updateVenueEmailLog (state, data) {
		let current = VenueService.getVenueEmailLogByVenueId(data.venueId)
		let formatedVenueLogEmails = VenueService.formatVenueComponentData(
			data.emailLogs,
			current
		)
		Vue.set(state.venueEmailLogTotal, data.venueId, data.total)
		Vue.set(state.venueEmailLog, data.venueId, formatedVenueLogEmails)
	},
	updateVenueRecommendations (state, data) {
		let current = VenueService.getVenueRecommendationsByVenueId(data.venueId)
		let formatedRecommendations = VenueService.formatVenueComponentData(
			data.recommendations,
			current
		)
		Vue.set(state.venueRecommendationsTotal, data.venueId, data.total)
		Vue.set(state.venueRecommendations, data.venueId, formatedRecommendations)
	},
	updateVenueStatus (state, data) {
		state.venueLostStatus = data
	},
	updateVenueStyles (state, data) {
		state.venueStyles = data
	},
	updateVenueRegions (state, data) {
		if (data.country) {
			Vue.set(state.venueRegionsFiltered, data.country, data.data);
		} else {
			state.venueRegions = data.data;
		}
	},
	updateVenueCities(state, data) {
		Vue.set(state.venueCities, data.country, data.data);
	},
	updateVenueSpecialists (state, data) {
		state.venueSpecialists = data
	},
	updateDescription (state, data) {
		Vue.set(state.descriptions, data.venue_id, data.description)
	},
	updatePackages (state, data) {
		Vue.set(state.packages, data.venue_id, data.packages)
	},
	updateNotes (state, data) {
		Vue.set(state.notes, data.venue_id, data.notes)
	},
	updateTopVenuesLossTo (state, data) {
		Vue.set(state.topVenuesLossTo, data.venueId, data.venues)
	},
	updateLostReasons (state, data) {
		Vue.set(state.lostReasons, data.venueId, data.reasons)
	},
	updateVenueLinkedEmails (state, data) {
		Vue.set(state.venueLinkedEmails, data.venueId, data.emails)
	},
	updateVenueSpecialOffers(state, data) {
		Vue.set(state.venueSpecialOffers, data.venueId, data.offers)
	}
}
export const getters = {
	getVenueById: state => id => {
		let venue = state.singleItems.find(
			venue => parseInt(venue.id) === parseInt(id)
		)
		if (!venue) {
			venue = state.items.find(venue => parseInt(venue.id) === parseInt(id))
		}
		return venue || {}
	},
	getVenueLeads: state => id => {
		return typeof state.venueLeads[id] !== 'undefined' && state.venueLeads[id]
			? state.venueLeads[id]
			: []
	},
	getVenueLeadsTotal: state => id => {
		return typeof state.venueLeadsTotal[id] !== 'undefined' &&
	  state.venueLeadsTotal[id]
			? state.venueLeadsTotal[id]
			: []
	},
	getVenueEmailLogs: state => id => {
		return typeof state.venueEmailLog[id] !== 'undefined' &&
	  state.venueEmailLog[id]
			? state.venueEmailLog[id]
			: []
	},
	getVenueEmailLogTotal: state => id => {
		return typeof state.venueEmailLogTotal[id] !== 'undefined' &&
	  state.venueEmailLogTotal[id]
			? state.venueEmailLogTotal[id]
			: []
	},
	getVenueRecommendations: state => id => {
		return typeof state.venueRecommendations[id] !== 'undefined' &&
	  state.venueRecommendations[id]
			? state.venueRecommendations[id]
			: []
	},
	getVenueRecommendationsTotal: state => id => {
		return typeof state.venueRecommendationsTotal[id] !== 'undefined' &&
	  state.venueRecommendationsTotal[id]
			? state.venueRecommendationsTotal[id]
			: []
	},
	getVenueDescription: state => id => {
		return typeof state.descriptions[id] !== 'undefined' &&
	  state.descriptions[id]
			? state.descriptions[id]
			: ''
	},
	getVenuePackages: state => id => {
		return typeof state.packages[id] !== 'undefined' && state.packages[id]
			? state.packages[id]
			: ''
	},
	getVenueNote: state => id => {
		return typeof state.notes[id] !== 'undefined' && state.notes[id]
			? state.notes[id]
			: ''
	},
	getTopVenuesLossTo: state => id => {
		return typeof state.topVenuesLossTo[id] !== 'undefined' &&
	  state.topVenuesLossTo[id]
			? state.topVenuesLossTo[id]
			: ''
	},
	getLostReasons: state => id => {
		return typeof state.lostReasons[id] !== 'undefined' && state.lostReasons[id]
			? state.lostReasons[id]
			: ''
	},
	getLostStatuses: state => id => {
		return state.venueLostStatus
	},
	getVenueLinkedEmails: state => id => {
		return typeof state.venueLinkedEmails[id] !== 'undefined' &&
	  state.venueLinkedEmails[id]
			? state.venueLinkedEmails[id]
			: ''
	},
	getVenueSpecialOffers: state => id => {
		return typeof state.venueSpecialOffers[id] !== 'undefined' &&
			state.venueSpecialOffers[id]
					? state.venueSpecialOffers[id]
					: ''
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
